import styled from 'styled-components';

export const NavBar = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  height: ${(props) => props.theme.navbar.height};
  background-color: ${(props) => props.theme.sidebar.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavHeader = styled.div`
  display: flex;
  color: ${(props) => props.theme.sidebar.textColor};
  margin-left: 16px;
  height: 25px;
`;

export const DropdownContainer = styled.div`
  position: relative;
`;

export const UserDropdown = styled.button`
  margin-right: 16px;
  color: ${(props) => props.theme.sidebar.textColor}80;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Menu = styled.ul`
  position: absolute;
  display: ${(props) => (props.show ? 'block' : 'none')};
  top: 0.75rem;
  left: 0;
  z-index: 9999;
  min-width: 8rem;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  list-style: none;
`;

export const MenuItem = styled.li`
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

export const MenuItemLink = styled.span`
  color: inherit;
`;
