import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
  MenuItemLink,
  NavBar,
  DropdownContainer,
  UserDropdown,
  NavHeader,
} from './styled.elements';

function MenuBar({ id }) {
  const { user, logout } = useAuth0();
  const [showDropdown, setShowDropdown] = useState(false);
  const navBarRef = useRef();

  // Register effect hook to detect outside clicks to close the dropdown.
  useEffect(() => {
    const handler = (event) => {
      if (showDropdown && navBarRef.current && !navBarRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      // Cleanup the event listener.
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [showDropdown]);

  return (
    <NavBar ref={navBarRef} id={id}>
      <NavHeader>
        <img src="https://cdn.pricecypher.com/pricecypher_white.svg" alt="PriceCypher logo" />
        <span>&nbsp;|&nbsp;API Documentation</span>
      </NavHeader>
      <DropdownContainer>
        <UserDropdown onClick={() => setShowDropdown((prev) => !prev)}>
          <em>{user.name}</em>
        </UserDropdown>
        <Menu show={showDropdown}>
          <MenuItem onClick={() => logout({ returnTo: window.location.href })}>
            <MenuItemLink>
              Logout
            </MenuItemLink>
          </MenuItem>
        </Menu>
      </DropdownContainer>
    </NavBar>
  );
}

MenuBar.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MenuBar;
