import { RedocStandalone } from 'redoc';
import { ThemeProvider } from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import MenuBar from '../MenuBar/MenuBar';
import { Main } from './styled.elements';
import config from '../../config.json';

const theme = {
  logo: {
    gutter: '20px',
  },
  sidebar: {
    backgroundColor: '#004956',
    textColor: '#ffffff',
  },
  rightPanel: {
    backgroundColor: '#374955',
  },
  colors: {
    http: {
      get: '#007C46',
    },
    success: {
      main: '#007C46',
    },
  },
  typography: {
    links: {
      color: '#004956',
    },
  },
  navbar: {
    height: '50px',
  },
};

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    user,
  } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Show error if user could not be authenticated.
  if (error) {
    return (
      <div>
        Oops...
        {error.message}
      </div>
    );
  }

  // Redirect to login page if not authenticated.
  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  const userPermissions = user[config.auth.permissionsClaim];

  // Show error if authorization details are not configured correctly.
  if (!userPermissions || !config.auth.requiredPermission) {
    return (
      <div>Oops... invalid site configuration.</div>
    );
  }

  // Check if the user is authorized to view the documentation page.
  if (!userPermissions.includes(config.auth.requiredPermission)) {
    return (
      <div>Not authorized</div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <MenuBar id="menubar" />
      <Main>
        <RedocStandalone
          specUrl={`${process.env.PUBLIC_URL}/openapi.yaml`}
          options={{
            theme,
            scrollYOffset: '#menubar',
            pathInMiddlePanel: true,
          }}
        />
      </Main>
    </ThemeProvider>
  );
}

export default App;
