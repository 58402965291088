import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import config from './config.json';

ReactDOM.render(
  <Auth0Provider
    domain={config.auth.domain}
    clientId={config.auth.clientId}
    audience={config.auth.audience}
    scope={config.auth.requiredPermission}
    redirectUri={window.location.href}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
